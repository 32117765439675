@import "../global/variables.scss";
@import "../global/mixins.scss";
.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error-code {
  font-size: 10rem;
  border-bottom: 1px solid $color-light;
}
.title {
  text-align: center;
}
.logo {
  bottom: 16px;
}
